.modal-deadline {
    &.ant-modal {
        margin: 0 !important;
    }
}

.explanation-task {
    border-radius: 16px;
    background: var(--Backgrounds-Secoundary, #f5f7fa);
    padding: 15px;
    margin: 8px 0;

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__image {
        width: 32px;
    }

    &__name {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 0 8px;
    }

    &__text {
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0 6px 0;
    }
}

.explanation-modal {
    width: 800px !important;
    color: var(--texts-icons-primary, #001965);
    font-family: 'Quicksand', sans-serif;

    &__main {
        padding: 35px 56px 56px 56px;
    }

    &__image {
        width: 72px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0 0 0;
    }
    &__subtitle {
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0 0 0;
    }

    &__what {
        font-size: 16px;
        font-weight: 600;
        margin: 20px 0;
    }

    &__tasks {
    }
}

.modal-pitch {
    width: 700px !important;

    &.ant-modal {
        margin: 0 !important;
    }
}

.upload-documents-modal {
    .main {
        height: 600px;
        overflow: auto;
    }

    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 60px 0px 60px;
    }

    &__btn {
        width: 48% !important;
    }
}

.modal-supplier-patricipants {
    width: 600px !important;
}

.modal-supplier {
    width: 800px !important;

    &.ant-modal {
        margin: 40px 0 !important;
    }
}

.modal {
    font-family: 'Quicksand', sans-serif;

    &__list {
        height: 240px;
        overflow: auto;
        padding: 0 20px 0 0;
        width: 105%;

        &.TR {
            height: 350px;
        }
    }

    .phase {
        border-radius: 8px;
        border: 1px solid var(--borders-lines-primary, #e6e8f0);
        margin: 0 0 8px 0;

        &__header {
            border-bottom: 1px solid var(--borders-lines-primary, #e6e8f0);
            background: var(--Backgrounds-Secoundary, #f5f7fa);
            padding: 12px;
            color: var(--texts-icons-primary, #001965);
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    margin: 0 0 0 5px;
                    cursor: pointer;
                }
            }
        }

        &__task {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 12px;
            color: var(--texts-icons-primary, #001965);
            font-size: 14px;
            font-weight: 500;

            &-name {
                width: 240px;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
            }

            &-value {
                text-wrap: nowrap;
            }

            &-days {
                text-wrap: nowrap;
            }
        }
    }

    &__note {
        display: flex;
        color: var(--texts-icons-primary, #001965);
        font-size: 14px;
        font-weight: 500;
        border-radius: 12px;
        background: var(--Backgrounds-Tertiary, #eaeff5);
        padding: 10px 20px;
        line-height: 20px;
        margin: 24px 45px 0 45px;

        img {
            margin: 0 17px 0 0;
        }
    }

    &__note-text {
        color: #e64a0d;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 10px 0;
    }

    &__slotsleft {
        color: var(--texts-icons-primary, #001965);
        font-size: 12px;
        font-weight: 600;
        border-radius: 40px;
        background: var(--Backgrounds-Additional, #c1d7ec);
        padding: 1px 8px;
    }

    &.pitch {
        height: calc(100vh - 97px);
        position: relative;

        .main {
            height: calc(100vh - 217px);
            overflow: scroll;
        }
    }

    &__boldtext {
        color: var(--texts-icons-primary, #001965);
        font-size: 14px;
        font-weight: 600;
    }

    &__duration {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            width: 31%;
            padding: 7px 40px;
            cursor: pointer;
            transition: all 0.3s;

            &.active {
                border: 1px solid var(--borders-lines-accent, #001965);
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                transition: all 0.3s;
            }

            &-image {
                margin: 0 8px 0 0;
            }

            &.people {
                width: 22%;
            }

            &.slot {
                width: 100%;
                justify-content: center;
                height: 40px;
            }
        }
    }

    &__nametitle {
        width: 425px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    &__subtitle {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 16px;
        font-weight: 500;
    }

    &-modal {
        width: 600px !important;

        &.ant-modal {
            margin: 0 !important;
        }

        &.timeline {
            width: 1290px !important;
        }
    }

    &__header {
        height: 140px;
        background: #f5f7fa;
        border-radius: 20px 20px 0 0;

        &.addsupplier {
            height: auto;
            padding: 24px 56px;
            min-height: 90px;
        }

        &.addsuppliermodal {
            height: auto;
            padding: 0 56px 0 0;
            min-height: 90px;
        }

        &.edit-supplier-header {
            height: 90px !important;
            padding: 0;
        }

        &.meeting {
            @media (max-width: 500px) {
                height: 80px !important;
            }
        }

        &.deadline {
            height: 72px;
        }

        @media (max-width: 620px) {
            height: 88px !important;
        }
    }

    &__title {
        color: #001965;
        font-weight: 500;
        font-size: 24px;
        padding: 30px 56px 0 56px;
        max-width: 555px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;

        &.long {
            text-wrap: wrap;
        }

        &.name-title {
            width: 89%;
            height: 68px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            cursor: pointer;
        }

        &.deadline {
            font-size: 20px;
            padding: 23px 32px 0 32px;
        }

        @media (max-width: 620px) {
            padding: 27px 30px 0 30px;
        }
        @media (max-width: 500px) {
            padding: 27px 20px 0 20px;
            font-size: 20px;
        }
    }

    &__id {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 0 57px;
    }

    &__avatar-wrap {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 84px;
        left: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__addtext {
        margin: 18px 0 0 0;
        cursor: pointer;
    }

    &__addbtn {
        color: #001965;
        font-size: 16px;
        font-weight: 600;
        padding: 7px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 120px;
    }

    &__edit {
        position: absolute;
        top: 26px;
        right: 105px;
        color: #001965;
        width: 107px;
        justify-content: space-evenly;
        border: 1px solid #e6e8f0;
        border-radius: 40px;
        padding: 7px 0;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        cursor: pointer;
    }

    &__delete-avatar-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    .main {
        padding: 116px 56px 43px 56px;

        &__title-no-head {
            color: var(--texts-icons-primary, #001965);
            font-size: 24px;
            font-weight: 500;
            margin: 10px 0 24px 0;
        }

        &__timelineModal {
            display: flex;
            border-bottom: 1px solid rgb(241, 241, 241);
        }

        &__terder-info {
            width: 430px;
            margin: 0 10px 0 0;
            padding: 0 30px 0 0;
            border-right: 1px solid rgb(241, 241, 241);

            @media (max-width: 1400px) {
                width: 390px;
            }
        }

        &.row {
            display: flex;
        }

        .supplier-list {
            display: flex;
            flex-direction: column;
            margin: 20px 0;

            &__item {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid var(--borders-lines-primary, #e6e8f0);
                padding: 10px 0;
            }

            &__companyname {
                color: var(--texts-icons-primary, #001965);
                font-size: 14px;
                font-weight: 500;
            }

            &__tradingname {
                color: var(--texts-icons-primary, #001965);
                font-size: 12px;
                font-weight: 500;
            }

            &__incumbent {
                color: var(--Secondary-Negative, #e80a0f);
                font-size: 14px;
                font-weight: 500;
                border-radius: 20px;
                background: var(--Backgrounds-Error, #fde6e7);
                display: flex;
                align-items: center;
                padding: 4px 15px;
                margin: 0 10px 0 0;

                img {
                    margin: 0 5px 0 0;
                }
            }
        }

        &__supplier-table {
            // border: 1px solid var(--borders-lines-primary, #e6e8f0);
            // border-radius: 11px;
            margin: 16px 0;
        }

        &__supplierts-selected {
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
            margin: 15px 0 0 0;
        }

        &__readonlyinfo {
            background: #f5f7fa;
            padding: 0 24px;
            border-radius: 26px;
            margin: 0 0 24px 0;
        }

        &__attention {
            background: #eaeff5;
            padding: 16px 20px;
            border-radius: 12px;
            color: #001965;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 16px 0;

            img {
                margin: 0 10px 0 0;
            }
        }

        &__minititle {
            color: #4d5e93;
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 24px 0;
        }

        .item-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #e6e8f0;

            &:last-child {
                border-bottom: none;
            }

            &__title {
                color: #808cb2;
                width: 50%;
            }

            &__value {
                color: 001965;
                width: 50%;
                text-align: start;
            }

            &__city {
                color: #4d5e93;
                font-size: 14px;
            }
        }

        .question-block {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                color: #001965;
                font-size: 16px;
                font-weight: 500;
            }
        }

        &__title {
            color: #001965;
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 16px 0;
            line-height: 20px;
        }

        &__name {
            color: #001965;
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 8px 0;
        }

        &__pitchtitle {
            color: var(--texts-icons-label, #808cb2);
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 30px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.deadline-main {
            padding: 10px 32px 20px 32px;
        }

        &__line {
            border-bottom: 1px solid #e6e8f0;
            width: 123%;
            margin: 0 0 0 -32px;

            @media (max-width: 620px) {
                width: 120%;
                margin: 0 0 0 -29px;
            }
            @media (max-width: 503px) {
                width: 111%;
                margin: 0px 0 0 -16px;
            }
        }

        @media (max-width: 620px) {
            padding: 20px 30px 43px 30px !important;
        }
        @media (max-width: 500px) {
            padding: 20px 20px 43px 20px !important;
        }
        &__subtitle {
            color: #808cb2;
            font-size: 14px;
            font-weight: 500;
        }

        &__file-error {
            font-size: 14px;
            font-weight: 500;
            color: #e80a0f;
            margin: 0 0 10px 0;
        }

        &__fullName {
            color: #001965;
            font-size: 24px;
            font-weight: 500;
            margin-top: -29px;
        }

        &__invite-btn {
            width: 100%;
            background: #001965;
            color: white;
            font-family: 'Quicksand', sans-serif;
            margin: 40px 0 0 0;
            font-size: 16px;
            font-weight: 600;
            border-radius: 40px;
            padding: 8px 0px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background: var(--buttons-main-hover, #334784);
                transition: all 0.3s;
            }
        }

        &__mainInfo-wrap {
            display: flex;
            align-items: center;
            height: 40px;
        }

        &__role {
            margin: -26px 0 0px 23px;
        }

        &__wrap-for-info {
            display: flex;
            align-items: center;
            padding: 24px 0 24px 0;
            border-bottom: 1px solid #e6e8f0;

            &.school {
                flex-direction: column;
                align-items: flex-start;
            }

            &.last {
                border-bottom: none;
                padding: 24px 0 5px 0;
            }

            .job {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                &__title {
                    color: #808cb2;
                }
                &__value {
                    color: #001965;
                    font-weight: 500;
                    padding: 0 0 0 8px;
                }
            }
        }

        &__info {
            display: flex;
            align-items: center;
            color: #808cb2;
            font-size: 16px;
            margin: 0 24px 0 0;

            span {
                padding: 0 0 0 5px;
            }
        }

        &__block {
            border-bottom: 1px solid #e6e8f0;

            &:first-child {
                padding: 0 0 8px 0;
            }
            &:last-child {
                border: none;
            }
            padding: 24px 0 12px 0;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            margin: 16px 0 0 0;

            &.for-phone {
                @media (max-width: 600px) {
                    flex-direction: column;
                }
            }
        }

        &__radiogroup {
            width: 100%;
        }

        &__radio-btn {
            border: 1px solid #ccd1e0;
            border-radius: 40px;
            padding: 11px;
            width: 100%;
            color: #001965;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 16px 0;

            &.edit-modal {
                border: none;

                &:hover {
                    border: none;
                    color: #001965;
                    cursor: context-menu;
                }
            }

            &:hover {
                color: #334784;
                border: 1px solid #b3bad1;
            }
        }
    }

    .view-supplier {
        padding: 24px 56px 0 56px;
    }

    .supplier {
        padding: 43px 56px;

        &.row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0;
        }

        &__row {
            padding: 0 20px 0 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
        }

        &__title {
            color: var(--texts-icons-label, #808cb2);
            text-wrap: nowrap;
            min-width: 200px;
        }

        &__value {
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            text-align: left;
            margin: 0 0 0 30px;
            min-width: 220px;
        }

        .expense_areas {
            display: flex;
            flex-direction: column;

            &__item {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &__name-wrapper {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                text-wrap: wrap;
            }

            &__ratings {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &__rating {
                border-radius: 99px;
                background: var(--Backgrounds-Tertiary, #eaeff5);
                width: 38px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--Buttons-Main, #001965);
                font-family: 'Quicksand', sans-serif;
                font-weight: 600;
                font-size: 14px;
                margin: 5px 0 0 8px;
                cursor: pointer;

                svg {
                    margin: 0 3px 0 0;
                }
            }
        }

        &__steps {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 24px 0;
            color: var(--texts-icons-label, #808cb2);

            &.active {
                color: var(--texts-icons-primary, #001965);
            }
        }

        &__step {
            display: flex;
            align-items: center;
            font-weight: 600;

            &.active {
                color: var(--texts-icons-primary, #001965);

                .supplier__number {
                    border: 1px solid var(--borders-lines-primary, #001965);
                }
            }

            &.done {
                .supplier__number {
                    background-color: #001965;
                }
            }
        }

        &__number {
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 0 0;
        }

        &__subtitle {
            color: var(--texts-icons-label, #808cb2);
            font-size: 14px;
            font-weight: 500;
        }

        &__column {
            width: 35%;
            &:first-child {
                width: 65%;
            }
        }

        &__email-wrap {
            display: flex;

            img {
                margin: 0 5px 0 0;
            }
        }

        &__email {
            color: var(--texts-icons-label, #808cb2);
            margin: 8px 0;
        }

        &__status {
            font-size: 12px;
            font-weight: 500;
            padding: 0 10px;
            margin: 0 0 0 25px;
            display: inline-block;
            border-radius: 8px;

            &.primary {
                color: var(--Primary-White, #fff);
                background: var(--Primary-Dark, #001965);
            }

            &.secondary {
                color: var(--Primary-White, #001965);
                background: var(--Backgrounds-Secoundary, #f5f7fa);
            }
        }
    }
}

.cancel {
    &:hover {
        background-color: #fafbfc;
        color: #001965 !important;
        border: 1px solid #e6e8f0 !important;
    }
    &:active {
        background-color: #ffffff;
        color: #001965 !important;
        border: 1px solid #001965 !important;
    }
}

.ant-modal-content {
    padding: 0 !important;
    border-radius: 20px !important;
}

.ant-modal-close {
    img {
        position: absolute;
        top: 12px;
        right: 39px;

        @media (max-width: 620px) {
            top: 6px;
            right: 8px;
        }
    }

    .image-deadline-close {
        position: absolute;
        top: 0px;
        right: 16px;
    }

    &:hover {
        color: transparent;
        background: none !important;
    }
}

.ant-upload {
    border: none !important;
    width: 120px;
    height: 120px;
    margin: 0 !important;

    img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;
    }
}
.ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 56px 56px 56px !important;
    margin-top: -30px !important;

    @media (max-width: 620px) {
        padding: 0 30px 27px 30px;
    }

    @media (max-width: 500px) {
        padding: 0 20px 27px 20px;
    }
}

.footer-modal-deadline {
    padding: 0 32px 5px 32px;
    margin: -29px 0 0 0;

    @media (max-width: 620px) {
        margin: -48px 0 27px 0;
    }
}

.ant-input-disabled {
    background-color: #ebecf0 !important;
    padding-top: 22px !important;
    color: gray !important;
}

.ant-modal {
    margin: 40px 0 250px 0;
}

.view {
    .ant-modal-footer {
        display: none;
    }
}

.modal-confirm {
    font-family: 'Quicksand', sans-serif;
    padding: 40px 56px;
    color: var(--texts-icons-primary, #001965);
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 500px) {
        padding: 20px 20px 53px 20px;
    }

    &__delete {
        padding: 0 56px;
        margin: 24px 0 56px;
        color: #001965;
    }
    &__title {
        color: #001965;
        font-size: 24px;
        font-weight: 500;

        @media (max-width: 500px) {
            width: 238px;
        }
    }
    &__subtitle {
        color: #001965;
        font-size: 16px;
        font-weight: 500;
        margin: 15px 0 0 0;

        span {
            color: #001965;
            font-weight: 600;
        }
    }

    .tasks__list {
        padding: 20px 0;
        margin: 0 0 15px 0;
    }

    &__next-btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 162px;
    }

    &__btn-wrap {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .task {
        height: 64px;
        border: 1px solid #e6e8f0;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        @media (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            height: 112px;
            padding: 0;
        }

        &__title {
            color: #001965;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;

            @media (max-width: 500px) {
                border-bottom: 1px solid #e6e8f0;
                width: 100%;
                height: 50%;
                padding: 0 12px;
            }

            img {
                width: 32px;
                margin: 0 10px 0 0;
            }
        }

        &__btn-wrap {
            display: flex;
            align-items: center;

            @media (max-width: 500px) {
                width: 100%;
                justify-content: center;
                height: 50%;
                margin: 0 0 0 -10px;
            }

            img {
                border-radius: 50%;
                width: 40px;
                margin: 0 16px 0 0;
            }
        }
        &__btn {
            background: #001965;
            color: white;
            font-size: 16px;
            font-weight: 600;
            padding: 10px 24px;
            border-radius: 40px;
            cursor: pointer;
        }

        &__btn-decline {
            color: #001965;
            font-size: 16px;
            border: 1px solid #e6e8f0;
            font-weight: 600;
            padding: 10px 24px;
            border-radius: 40px;
            cursor: pointer;
        }

        &__received {
            display: flex;
            align-items: center;
            color: #098e1d;
            font-size: 16px;
            font-weight: 600;

            img {
                width: 20px;
                margin: 0 7px 0 18px;
            }
        }
    }

    &__title {
        color: #001965;
        font-size: 20px;
        font-weight: 500;
    }

    .info-wrap {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;

        &__deadline {
            color: #808cb2;
            margin: 0 8px 0 0;
        }
        &__status {
            color: #001965;
            margin: 0 16px 0 0;
        }
        &__edit {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e6e8f0;
            border-radius: 40px;
            width: 107px;
            height: 40px;
            cursor: pointer;

            img {
                margin: 0 12px 0 0;
            }
        }
    }
}
.quit {
    width: 100%;
    border-radius: 40px;
    background: #facecf;
    color: #e80a0f;
    border: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;

    &:hover {
        background: #facecf !important;
        color: #e80a0f !important;
    }
}

.upload-button {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
}

.upload-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.slot-list {
    margin: 0px 0 40px 0;
}

.slot {
    width: 100%;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    border-radius: 40px;
    position: relative;
    margin: 0 0 16px 0;

    .customSelect__options {
        max-height: 107px !important;
    }

    &.error {
        border: 1px solid #e80a0f;
    }

    &__form {
        padding: 16px 16px 3px 16px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 500;
        padding: 13px 30px 13px 30px;
        border-bottom: 1px solid var(--borders-lines-primary, #e6e8f0);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.pitch-time {
    width: 48%;

    .select-option__name {
        width: 230px;
    }
}

.addsupplier {
    color: var(--texts-icons-primary, #001965);
    font-weight: 500;

    &__company_name {
        font-size: 24px;
    }

    &__trading_name {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 20px;
    }

    &__status {
        font-size: 14px;
        position: relative;
    }

    &__comment {
        margin: 50px 0 0 0;
        border-radius: 8px;
        border: 1px solid var(--borders-lines-primary, #e6e8f0);
        background: var(--Primary-White, #fff);
        padding: 8px 12px;
        width: 77%;

        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--texts-icons-label, #808cb2);
            font-size: 14px;
            font-weight: 500;
        }

        &-value {
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
        }
    }

    &__contact_name {
        font-size: 20px;
    }

    &__job_title {
        font-size: 16px;
    }
}

.documents {
    border-radius: 16px;
    background: var(--Backgrounds-Secoundary, #f5f7fa);
    width: 100%;
    margin: 0 0 30px 20px;
    padding: 16px;

    .requested {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px 0 0 0;

        &__status {
            font-weight: 500;
        }

        ul {
            padding: 0 0px 0 20px;
        }
    }

    &__title {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 16px;
        font-weight: 500;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;
    }

    &__download {
        width: 32px;
        cursor: pointer;
    }

    &__download-wrap {
        width: 32px;
        height: 32px;
    }

    &__doc-title {
        color: var(--texts-icons-label, #808cb2);
        font-size: 14px;
        font-weight: 500;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    &__doc {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 500;
        display: flex;
        cursor: pointer;

        img {
            margin: 0 5px 0 0;
        }
    }

    &__name {
        max-width: 150px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__btn {
        width: 100%;
        text-align: center;
        color: var(--Buttons-Main, #001965);
        font-size: 16px;
        font-weight: 600;
        border-radius: 40px;
        border: 1px solid var(--borders-lines-primary, #e6e8f0);
        background: var(--Primary-White, #fff);
        display: inline-block;
        padding: 7px 24px;
        margin: 20px 0 0 0;
        cursor: pointer;

        &.newTpa {
            width: 78%;
            text-wrap: nowrap;
            margin: 0;
            padding: 7px 0;
        }
    }

    &__no-documents {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
        color: var(--texts-icons-primary, #001965);
        font-size: 14px;
        font-weight: 500;
    }
}

.add_supplier_row {
    border: 1px solid grey !important;
}

.campus-tour-item {
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    border-radius: 30px;
    margin: 10px 0;

    &__header {
        padding: 12px 20px;

        &__name {
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
        }

        &__tr-name {
            color: var(--texts-icons-secondary, #4d5e93);
            font-size: 14px;
            font-weight: 500;
            margin: 8px 0 0 0;
        }
    }

    &__main {
        padding: 12px 20px 0 20px;

        &__title {
            color: var(--texts-icons-label, #808cb2);
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Buttons-Main, #001965);
        font-size: 16px;
        font-weight: 600;
        padding: 12px 20px;
        cursor: pointer;

        img {
            margin: 0 5px 0 0;
        }
    }
}

.rating {
    font-family: 'Quicksand', sans-serif;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__rating {
        border-radius: 99px;
        background: var(--Backgrounds-Tertiary, #eaeff5);
        width: 38px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Buttons-Main, #001965);
        font-family: 'Quicksand', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin: 5px 0 0 8px;
        cursor: pointer;

        svg {
            margin: 0 3px 0 0;
        }
    }
    &__title {
        color: var(--Buttons-Main, #001965);
        font-size: 16px;
        font-weight: 600;
    }
    &__subtitle {
        color: var(--Buttons-Main, #001965);
        font-size: 16px;
        font-weight: 500;
    }

    &__text {
        color: var(--texts-icons-label, #808cb2);
        font-size: 14px;
        font-weight: 500;
    }
}

.name {
    color: var(--texts-icons-primary, #001965);
    font-weight: 500;
    font-size: 14px;
}

.tr_name {
    color: var(--texts-icons-primary, #001965);
    font-weight: 500;
    font-size: 12px;
}

.shortlisted {
    color: var(--Secondary-Success, #098e1d);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

/* TenderCalendar.module.css */

/* Обертка для календаря */
.calendarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 100%;
}

/* Кнопки навигации */
.navButton {
    font-size: 20px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
}

/* Уникальные стили для календаря */
.calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 740px;
}

/* Кнопки навигации */
.nav-button {
    font-size: 20px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
}
.row {
    display: flex;

    .calendar-container {
        display: flex;

        .react-datepicker {
            display: flex;

            .react-datepicker__month-container {
                border: 1px solid var(--borders-lines-primary, #e6e8f0);
                margin: 5px;
                width: 265px;
                border-radius: 16px 16px 0px 0px;

                .react-datepicker__header {
                    border-radius: 16px 16px 0px 0px;
                    border-bottom: 1px solid #e6e8f0 !important;

                    .react-datepicker__current-month {
                        color: var(--texts-icons-primary, #001965);
                        font-size: 16px;
                        font-weight: 500;
                        margin: 15px 0;
                    }
                }

                .react-datepicker__month {
                    margin: 0 !important;
                    .react-datepicker__week {
                        .react-datepicker__day {
                            border: 1px solid var(--borders-lines-primary, #d9dce4) !important;
                            margin: 0 !important;
                            padding: 31px 14px;
                            min-width: 36px !important;
                            cursor: pointer;
                            transition: all 0.3s;

                            &:hover {
                                border-radius: 0 !important;
                                transition: all 0.3s;

                                &.first_day {
                                    border-radius: 16px 0 0 16px !important;
                                    background: var(--Primary-Dark, #ccd1e0) !important;
                                    transition: all 0.3s;
                                }
                                &.last_day {
                                    border-radius: 0 16px 16px 0 !important;
                                    background: var(--Primary-Dark, #ccd1e0) !important;
                                    transition: all 0.3s;
                                }
                            }
                        }

                        .react-datepicker__day--today {
                            background-color: white !important;
                            border-radius: 0 !important;

                            div {
                                min-width: 30px !important;
                                min-height: 30px !important;
                                border: 1px solid #e64a0d !important;
                                border-radius: 50% !important;
                                color: #e64a0d !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .react-datepicker__day--outside-month {
                            background-color: rgb(255, 255, 255) !important;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}
/* Стили для фаз */
.phase-1 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}

.phase-1:hover,
.phase-1:hover ~ .phase-1,
:has(.phase-1:hover) .phase-1 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}

.phase-2 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}

.phase-2:hover,
.phase-2:hover ~ .phase-2,
:has(.phase-2:hover) .phase-2 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}

.phase-3 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}

.phase-3:hover,
.phase-3:hover ~ .phase-3,
:has(.phase-3:hover) .phase-3 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}
.phase-4 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}
.phase-4:hover,
.phase-4:hover ~ .phase-4,
:has(.phase-4:hover) .phase-4 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}
.phase-5 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}
.phase-5:hover,
.phase-5:hover ~ .phase-5,
:has(.phase-5:hover) .phase-5 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}
.phase-6 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}
.phase-6:hover,
.phase-6:hover ~ .phase-6,
:has(.phase-6:hover) .phase-6 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}
.phase-7 {
    background-color: #e5e8f0 !important;
    color: #001965 !important;
    transition: all 0.3s;

    &.react-datepicker__day--today {
        background-color: #e5e8f0 !important;
        color: #001965 !important;
        border-radius: 0 !important;
    }
}
.phase-7:hover,
.phase-7:hover ~ .phase-7,
:has(.phase-7:hover) .phase-7 {
    background: var(--Primary-Dark, #ccd1e0) !important;
    transition: all 0.3s;
    color: #001965 !important;
}

.first_day {
    background-color: #e5e8f0 !important;
    border-radius: 16px 0 0 16px;
    color: #001965 !important;

    &:hover {
        // background-color: #e5e8f0 !important;
        border-radius: 16px 0 0 16px !important;
        color: #001965 !important;
    }
}
.last_day {
    background-color: #e5e8f0 !important;
    border-radius: 0 16px 16px 0;
    color: #001965 !important;

    &:hover {
        // background-color: #e5e8f0 !important;
        border-radius: 0 16px 16px 0 !important;
        color: #001965 !important;
    }
}

.day-styles {
    padding: 5px;
    font-family: 'Quicksand', sans-serif;

    &__title {
        color: var(--White, #fff);
        font-size: 12px;
        font-weight: 600;
    }

    &__days {
        color: var(--White, #fff);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__diff {
        margin: 0 0 0 5px;
        color: var(--White, #d9d7d7);
    }
}

.react-datepicker__navigation {
    background-color: #fff; /* Белый фон для кнопок */
    border: 1px solid #dadadb !important; /* Синий бордер */
    border-radius: 50% !important; /* Круглая форма */
    width: 30px !important;
    height: 30px !important;

    position: absolute;
    top: 20px !important;

    cursor: pointer;

    &.react-datepicker__navigation--previous {
        left: 23px !important;
    }
    &.react-datepicker__navigation--next {
        left: 740px !important;
    }

    .react-datepicker__navigation-icon {
        &:before {
            border-color: #1c3cb2;
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: block;
            height: 5px;
            position: absolute;
            top: 9px;
            left: -3px;
            width: 5px;
        }

        &:hover {
            border-color: #1c3cb2 !important;
        }
    }
}

.multipleModals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 57px;

    &__steps {
        display: flex;
    }

    &__step {
        width: 24px;
        height: 4px;
        border-radius: 16px;
        background: var(--texts-icons-primary, #001965);
        margin: 0 10px 0 0;

        &.inactive {
            background: var(--borders-lines-primary, #e6e8f0);
        }
    }
}
